var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "terminalManagement", staticClass: "terminal-management" },
    [
      _c("div", { ref: "functionButtons", staticClass: "function-buttons" }),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "90px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "终端号：", prop: "deviceNo" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入终端号" },
                    model: {
                      value: _vm.form.deviceNo,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "deviceNo", $$v)
                      },
                      expression: "form.deviceNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：", prop: "cph" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车牌号" },
                    model: {
                      value: _vm.form.cph,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "cph", $$v)
                      },
                      expression: "form.cph"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属企业：", prop: "company" } },
                [
                  _c("companySelect", {
                    attrs: {
                      clearable: true,
                      companyTree: _vm.companyList,
                      valueName: _vm.valueName,
                      value: _vm.value
                    },
                    on: { getValue: _vm.getGroupId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备型号：", prop: "deviceModelId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.deviceModelId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "deviceModelId",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.deviceModelId"
                      }
                    },
                    _vm._l(_vm.deviceModelIdList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.deviceName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备状态：", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "status",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.status"
                      }
                    },
                    _vm._l(_vm.statusList, function(item, key) {
                      return _c("el-option", {
                        key: key,
                        attrs: { label: item.deviceName, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "硬件版本号：", prop: "hardwareVersion" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入硬件版本号" },
                    model: {
                      value: _vm.form.hardwareVersion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "hardwareVersion", $$v)
                      },
                      expression: "form.hardwareVersion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "固件版本号：", prop: "firmwareVersion" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入固件版本号" },
                    model: {
                      value: _vm.form.firmwareVersion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "firmwareVersion", $$v)
                      },
                      expression: "form.firmwareVersion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSearchClick }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.tableData.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { attrs: { data: _vm.tableData, stripe: "", height: _vm.tableHeight } },
        [
          _c("el-table-column", { attrs: { type: "index", label: "序号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceNo",
              label: " 终端号",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serialNo",
              label: "自编号",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "cph", label: "车牌号", width: "120" }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属企业",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "deviceModelName",
              label: "设备型号",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "terminalModel",
              label: "终端型号",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "hardwareVersion",
              label: "硬件版本号",
              width: "160",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "firmwareVersion",
              label: "固件版本号",
              width: "170",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "protocolVersion",
              label: "协议版本",
              width: "140",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "lastUpdateTime",
              label: "更新时间",
              width: "180",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.showTerminal(scope.row, 1)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "current-change": _vm.onCurrentChange,
              "size-change": _vm.onSizeChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                },
                close: _vm.onDialogClose
              }
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "body" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "addForm",
                        staticClass:
                          "demo-form-inline dialog-form dialog-form-inline",
                        attrs: {
                          model: _vm.addForm,
                          "label-width": "120px",
                          "label-position": "left"
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "自编号：", prop: "serialNo" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.serialNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "serialNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.serialNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "终端号：", prop: "deviceNo" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.deviceNo,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "deviceNo",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.deviceNo"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "设备型号：",
                              prop: "deviceModelName"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.deviceModelName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "deviceModelName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.deviceModelName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "所属企业：", prop: "companyName" }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.companyName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "companyName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.companyName"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "车牌号：", prop: "cph" } },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.cph,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "cph",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.cph"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "终端型号：",
                              prop: "terminalModel"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.terminalModel,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "terminalModel",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.terminalModel"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "终端上报协议：",
                              prop: "protocolVersion"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.protocolVersion,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "protocolVersion",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.protocolVersion"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "终端SIM卡ICCID：", prop: "iccid" }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.iccid,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "iccid",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.iccid"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "硬件版本号：",
                              prop: "hardwareVersion"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.hardwareVersion,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "hardwareVersion",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.hardwareVersion"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "固件版本号：",
                              prop: "firmwareVersion"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.firmwareVersion,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "firmwareVersion",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.firmwareVersion"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.addForm.extend
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "制造商id：",
                                    prop: "makerID"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addForm.extend.makerID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addForm.extend,
                                          "makerID",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "addForm.extend.makerID"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "终端id：",
                                    prop: "terminalID"
                                  }
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value: _vm.addForm.extend.terminalID,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.addForm.extend,
                                          "terminalID",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "addForm.extend.terminalID"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "更新时间：",
                              prop: "lastUpdateTime"
                            }
                          },
                          [
                            _c("el-input", {
                              model: {
                                value: _vm.addForm.lastUpdateTime,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.addForm,
                                    "lastUpdateTime",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "addForm.lastUpdateTime"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "设备状态：", prop: "status" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { clearable: "", disabled: "" },
                                model: {
                                  value: _vm.addForm.status,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.addForm,
                                      "status",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "addForm.status"
                                }
                              },
                              _vm._l(_vm.statusList, function(item, key) {
                                return _c("el-option", {
                                  key: key,
                                  attrs: {
                                    label: item.deviceName,
                                    value: item.id
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }