<template>
  <!-- 终端属性 -->
  <div class="terminal-management" ref="terminalManagement">
    <div class="function-buttons" ref="functionButtons"></div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="90px"
      >
        <el-form-item label="终端号：" prop="deviceNo">
          <el-input
            v-model="form.deviceNo"
            placeholder="请输入终端号"
          ></el-input>
        </el-form-item>

        <el-form-item label="车牌号：" prop="cph">
          <el-input v-model="form.cph" placeholder="请输入车牌号"></el-input>
        </el-form-item>

        <el-form-item label="所属企业：" prop="company">
          <companySelect
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>

        <el-form-item label="设备型号：" prop="deviceModelId">
          <el-select clearable v-model.trim="form.deviceModelId">
            <el-option
              v-for="(item, key) in deviceModelIdList"
              :label="item.deviceName"
              :value="item.id"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="设备状态：" prop="status">
          <el-select clearable v-model.trim="form.status">
            <el-option
              v-for="(item, key) in statusList"
              :label="item.deviceName"
              :value="item.id"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="硬件版本号：" prop="hardwareVersion">
          <el-input
            v-model="form.hardwareVersion"
            placeholder="请输入硬件版本号"
          ></el-input>
        </el-form-item>

        <el-form-item label="固件版本号：" prop="firmwareVersion">
          <el-input
            v-model="form.firmwareVersion"
            placeholder="请输入固件版本号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="onSearchClick"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="deviceNo"
        label=" 终端号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="serialNo"
        label="自编号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="cph" label="车牌号" width="120"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="deviceModelName"
        label="设备型号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="terminalModel"
        label="终端型号"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="hardwareVersion"
        label="硬件版本号"
        width="160"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="firmwareVersion"
        label="固件版本号"
        width="170"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="protocolVersion"
        label="协议版本"
        width="140"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="lastUpdateTime"
        label="更新时间"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" width="90">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="showTerminal(scope.row, 1)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      @close="onDialogClose"
      width="70%"
    >
      <div>
        <div class="body">
          <el-form
            :model="addForm"
            ref="addForm"
            label-width="120px"
            label-position="left"
            class="demo-form-inline dialog-form dialog-form-inline"
          >
            <el-form-item label="自编号：" prop="serialNo">
              <el-input v-model.trim="addForm.serialNo"></el-input>
            </el-form-item>

            <el-form-item label="终端号：" prop="deviceNo">
              <el-input v-model.trim="addForm.deviceNo"></el-input>
            </el-form-item>

            <el-form-item label="设备型号：" prop="deviceModelName">
              <el-input v-model.trim="addForm.deviceModelName"></el-input>              
            </el-form-item>

            <el-form-item label="所属企业：" prop="companyName">
              <el-input v-model.trim="addForm.companyName"></el-input>
            </el-form-item>

            <el-form-item label="车牌号：" prop="cph">
              <el-input v-model.trim="addForm.cph"></el-input>
            </el-form-item>

            <el-form-item label="终端型号：" prop="terminalModel">
              <el-input
                v-model.trim="addForm.terminalModel"
              ></el-input>
            </el-form-item>

            <el-form-item label="终端上报协议：" prop="protocolVersion">
              <el-input
                v-model.trim="addForm.protocolVersion"
              ></el-input>
            </el-form-item>
            <el-form-item label="终端SIM卡ICCID：" prop="iccid">
              <el-input v-model.trim="addForm.iccid"></el-input>
            </el-form-item>
            <el-form-item label="硬件版本号：" prop="hardwareVersion">
              <el-input
                v-model.trim="addForm.hardwareVersion"
              ></el-input>
            </el-form-item>
            <el-form-item label="固件版本号：" prop="firmwareVersion">
              <el-input
                v-model.trim="addForm.firmwareVersion"
                
              ></el-input>
            </el-form-item>
            <template v-if="addForm.extend">
              <el-form-item label="制造商id：" prop="makerID">
                <el-input
                  v-model.trim="addForm.extend.makerID"
                ></el-input>
              </el-form-item>
              <el-form-item label="终端id：" prop="terminalID">
                <el-input
                  v-model.trim="addForm.extend.terminalID"
                  
                ></el-input>
              </el-form-item>
            </template>
            <el-form-item label="更新时间：" prop="lastUpdateTime">
              <el-input
                v-model.trim="addForm.lastUpdateTime"
                
              ></el-input>
            </el-form-item>
            <el-form-item label="设备状态：" prop="status">
              <el-select clearable v-model.trim="addForm.status" disabled>
                <el-option
                  v-for="(item, key) in statusList"
                  :label="item.deviceName"
                  :value="item.id"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryDeviceInspectPage,
  queryDeviceInspectInfo,
  getBusinessCompanyTree,
  queryAllDeviceModel
} from '@/api/lib/api.js'
import companySelect from '@/components/treeSelect/companySelect.vue'
import { getCurent, formatDate } from '@/common/utils/index'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'terminalManagement',
  components: {
    companySelect
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      form: {
        deviceNo: '',
        deviceModelId: '',
        cph: '',
        companyId: '',
        status: '',
        currentPage: 1,
        pageSize: 10,
        hardwareVersion: '',
        firmwareVersion: ''
      },
      addForm: {
        serialNo: '',
        deviceNo: '',
        sim: '',
        disk: '',
        deviceMac: '',
        deviceModelId: '',
        companyId: '',
        produceTime: ''
      },
      total: 0,
      title: '',
      deviceModelIdList: [],
      statusList: [
        { id: 0, deviceName: '正常' },
        { id: 1, deviceName: '禁用' },
      ],
      companyList: [],
      dialogVisible: false,
      exportForm: {},
      downLoadStatus: false,
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.terminalManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      let data = {
        baseUrl: 'report',
        userId: sessionStorage.getItem('userId') || localStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `终端属性-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/device/inspect/exportDeviceInspect'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    onSearchClick () {
      this.form.currentPage = 1
      this.onSearch()
    },
    onSearch () {
      let {
        deviceNo,
        deviceModelId,
        cph,
        companyId,
        currentPage,
        pageSize,
        status,
        firmwareVersion,
        hardwareVersion
      } = this.form
      let data = {
        deviceNo: deviceNo,
        deviceModelId: deviceModelId || null,
        cph: cph,
        companyId: companyId || null,
        currentPage: currentPage,
        pageSize: pageSize,
        status: status,
        firmwareVersion,
        hardwareVersion
      }
      queryDeviceInspectPage(data).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
          if (!res.data.list.length && this.form.currentPage !== 1) {
            // 如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            this.form.currentPage -= 1
            this.onSearch()
          }
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
        this.exportForm = { ...data }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.onSearch()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.onSearch()
    },
    showTerminal (row) {
      this.title = '查看终端信息';
      queryDeviceInspectInfo({ deviceNo: row.deviceNo }).then(res => {
        if (res.code === 1000) {
          this.$nextTick(() => {
            this.valueAdd = res.data.companyId;
            this.valueNameAdd = res.data.companyName;
            this.addForm = res.data;
            if (res.data && res.data.extend) {
              this.addForm.extend = JSON.parse(res.data.extend);
            } else {
              this.addForm.extend = {
                makerID: "",
                terminalID: ""
              };
            }            
          })
          this.dialogVisible = true;
        } else {
          this.$message.warning(res.msg);
        }
      })
    },
    // 关闭弹窗
    onDialogClose () {
      this.$refs.companySelect.clearHandle();
      this.$refs.addForm.resetFields();
      this.dialogVisible = false;
      this.valueAdd = null;
      this.valueNameAdd = '';
      this.addForm = {
        serialNo: '',
        deviceNo: '',
        sim: '',
        deviceType: '',
        deviceModel: '',
        companyId: '',
      };
    },
    getGroupId (v) {
      this.form.companyId = v
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    // 获取终端类型
    getAllDeviceModelList () {
      queryAllDeviceModel().then((res) => {
        if (res.code === 1000) {
          this.deviceModelIdList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取终端型号类型
    getDeviceModelList () {
      queryUserGroupTypeList({ parentId: 156 }).then((res) => {
        if (res.code === 1000) {
          this.deviceModelList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },

  },
  created () {
    this.onSearch()
    this.getCompanyList()
    this.getAllDeviceModelList()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.terminal-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-resourse {
  .terminal-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
